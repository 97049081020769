<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormWarehouse
		v-else
		:disabled-primary-button="isCreating"
		:warehouse-types="warehouseTypes"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import FormWarehouse from '@/components/FormWarehouse.vue';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'WarehouseCreate',
	components: {
		FormWarehouse,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('warehouses', {
			isCreating: (state) => state.create.isCreating,
		}),

		...mapGetters({
			warehouseTypes: 'warehouses/warehouseTypes',
		}),
	},
	async created() {
		this.isLoading = true;

		try {
			await Promise.all([
				this.fetchProvinces(),
				this.getWarehouseTypes({ per_page: 'all' }),
			]);
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getWarehouseTypes: 'warehouses/getWarehouseTypes',
			createWarehouse: 'warehouses/createWarehouse',
			fetchProvinces: 'addresses/fetchProvinces',
		}),
		async handleSubmit(params) {
			try {
				await this.createWarehouse(params);
				this.$router.push({ name: 'WarehouseList' });
			} catch (e) {
				if (e.response.data.errors.com7_branch_id) {
					this.isUniqueBranchId = false;
					scrollToTop();
				}
			}
		},
	},
};
</script>
